import { setRemoteDefinitions } from '@naeeman/load-remote-module';

import env from './environments/environment';

window.global = window;

import('./bootstrap');

let fileUrl = '/assets/module-federation.manifest.json';

if (env.environment === 'prod') {
  fileUrl = '/assets/module-federation.prod.manifest.json';
} else if (env.environment === 'qa') {
  fileUrl = '/assets/module-federation.qa.manifest.json';
} else if (env.environment === 'dev') {
  fileUrl = '/assets/module-federation.dev.manifest.json';
}

fetch(fileUrl)
  .then((res) => res.json())
  .then((definitions) => setRemoteDefinitions(definitions))
  .then(() => import('./bootstrap').catch((err) => console.error(err))); // eslint-disable-line no-console

import('./bootstrap');

if ((module as any).hot) {
  (module as any).hot.accept('./bootstrap', () => {
    import('./bootstrap').catch((err) => console.error(err));
  });
}
